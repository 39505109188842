<template>
    <v-stepper v-model="step">
        <v-stepper-header>
            <v-stepper-step
                :complete="step > 1"
                step="1"
                :editable="id !== null"
            >Basic Config</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
                :editable="id !== null"
                step="2">Mailing Settings</v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
            <v-stepper-content step="1">
                <v-form ref="formBasic" v-model="formBasicValid">
                    <v-row>
                        <v-col>
                            <v-text-field
                                label="Title"
                                v-model="inputs.title"
                                required
                                :rules="[rules.required, rules.counter]"
                                outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row class="flex-column align-center justify-center">
                        <v-col cols="12">
                            <strong v-text="'Campaign Flight'" class="text-uppercase"></strong>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-menu
                                v-model="menu.start_date"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="menu.start_date_formatted"
                                        :rules="[rules.required]"
                                        label="Start Date"
                                        hint="MM/DD/YYYY format"
                                        persistent-hint
                                        prepend-icon="fa-calendar-alt"
                                        @blur="inputs.start_date = dateService.parseDate(menu.start_date_formatted)"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="inputs.start_date"
                                    no-title
                                    @input="menu.start_date = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col>
                            <v-menu
                                v-model="menu.end_date"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="menu.end_date_formatted"
                                        :rules="[rules.required]"
                                        label="End Date"
                                        hint="MM/DD/YYYY format"
                                        persistent-hint
                                        prepend-icon="fa-calendar-alt"
                                        @blur="inputs.end_date = dateService.parseDate(menu.end_date_formatted)"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="inputs.end_date"
                                    no-title
                                    @input="menu.end_date = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>

                    <v-row class="mt-4">
                        <v-col>
                            <v-btn outlined text :to="'/dashboard'">Cancel</v-btn>
                        </v-col>
                        <v-col>
                            <v-btn
                                color="primary"
                                @click="step = 2;"
                                :disabled="!formBasicValid"
                            >Continue</v-btn>
                        </v-col>
                    </v-row>

                </v-form>
            </v-stepper-content>

            <v-stepper-content step="2">
                <v-form
                    :disabled="savingCreative"
                    ref="mailForm"
                    v-model="formMailingValid">

                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="inputs.subject"
                                label="Mail Subject"
                                :rules="[rules.required]"
                                required
                                outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="inputs.sender_name"
                                @input="onInputChanged($event, 'sender_name')"
                                label="Sender Name"
                                :rules="[]"
                                outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-text-field
                                label="Sender E-mail"
                                v-model="inputs.sender_email"
                                @input="onInputChanged($event, 'sender_email')"
                                :rules="inputs.sender_email !== null ? emailRules : []"
                                outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-card outlined>
                        <v-row class="flex-column align-center justify-center">
                            <v-col cols="12">
                                <strong v-text="'Recipients Options'" class="text-uppercase"></strong>
                            </v-col>
                            <v-col class="d-flex justify-center" cols="8">
                                <v-radio-group @change="resetRecipents" v-model="recipientsType" row>
                                    <v-radio label="From File" value="file"></v-radio>
                                    <v-radio label="From Sweepstake" value="sweepstakes"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>

                        <!-- File Option -->
                        <v-row class="justify-center" v-if="recipientsType === 'file'">
                            <v-col cols="8">
                                <v-file-input
                                    @click:clear="onFileClear"
                                    @change="onFileChanged($event)"
                                    :error="id !== null ? false : inputs.recipients === null"
                                    required
                                    label="Upload File(xls | xlsx)"
                                    outlined
                                    :rules="id !== null ? [] : [rules.file, rules.required]"
                                    accept=".xls, .xlsx"
                                ></v-file-input>
                            </v-col>
                            <v-col cols="2">
                                <v-btn
                                    @click="downloadSample"
                                    class="ma-3"
                                    outlined small
                                    color="white"
                                ><v-icon left small>fa-download</v-icon> Sample</v-btn>
                            </v-col>
                        </v-row>

                        <!-- Sweepstake Option -->
                        <v-card
                            class="sweepstake__options col-8 mb-3"
                            v-if="recipientsType === 'sweepstakes'"
                            outlined>
                                <p class="text-uppercase">Select Sweepstake</p>
                                <v-row class="align-center justify-center">
                                    <v-col cols="4">
                                        <v-btn-toggle
                                            v-model="sweepstakeMode"
                                            @change="inputs.sweepstake_id = null"
                                            borderless
                                        >
                                            <v-btn value="id">
                                                <span>By ID</span>
                                            </v-btn>

                                            <v-btn value="search">
                                                <span>Search</span>
                                            </v-btn>
                                            </v-btn-toggle>
                                    </v-col>
                                    <v-col cols="6" class="align-center">
                                        <v-text-field
                                            v-if="sweepstakeMode === 'id'"
                                            @input="onSweepSearch($event)"
                                            color="white"
                                            v-model="sweepstakeSearch.id"
                                            :rules="id !== null ? [] : [rules.required]"
                                            required
                                            :success="inputs.sweepstake_id !== null  "
                                            :error="id !== null ? (sweepstakeSearch.id !== '' ? inputs.sweepstake_id !== null : false ) : inputs.sweepstake_id === null"
                                            :append-icon="inputs.sweepstake_id !== null ? 'fa-check' : 'fa-times'"
                                            type="number"
                                            outlined
                                            :loading="searchingData"
                                            hide-details="auto"
                                            label="Sweepstake ID">
                                        </v-text-field>
                                        <v-combobox
                                            v-else
                                            @change="comboChanged($event)"
                                            ref="searchSweepInput"
                                            type="text"
                                            label="Search Sweepstake"
                                            v-model="sweepstakeSearch.keyword"
                                            :rules="id !== null ? [] : [rules.required]"
                                            :items="filteredEntries"
                                            :validate-on-blur="filteredEntries.length > 0"
                                            item-text="title"
                                            :search-input.sync="search"
                                            :loading="searchingData"
                                            no-filter
                                            required
                                        >
                                        </v-combobox>
                                    </v-col>
                                </v-row>
                        </v-card>
                    </v-card>

                    <v-row class="email-code__container">
                        <v-col>
                            <v-card dark>
                                <v-card-title class="d-flex justify-space-between primary col-12">Email Content
                                    <v-col class="d-flex justify-space-around" cols="2">
                                        <fullscreen-editor
                                            @onEditorClose="inputs.content = $event"
                                            :content="inputs.content"
                                            :editorOpts="codeOptions">
                                        </fullscreen-editor>
                                        <template-preview :content="inputs.content"></template-preview>
                                    </v-col>

                                </v-card-title>
                                    <codemirror
                                        ref="myCm"
                                        v-model="inputs.content"
                                        :options="codeOptions"
                                    >
                                    </codemirror>
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-row class="mt-4">
                        <v-col>
                            <v-btn outlined @click="step = 1">Go Back</v-btn>
                        </v-col>
                        <v-col>
                            <v-btn
                                color="primary"
                                @click="saveMailing"
                                :disabled="!shouldDisableSave"
                                :loading="savingCreative"
                            >{{ id !== null ? 'Save' : 'Finish' }}</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-stepper-content>
        </v-stepper-items>
    </v-stepper>
</template>
<script>
    import { codemirror } from 'vue-codemirror';
    import 'codemirror/mode/xml/xml.js';
    import 'codemirror/addon/display/autorefresh.js';
    import 'codemirror/addon/selection/active-line.js';
    import 'codemirror/addon/edit/matchbrackets.js';
    import 'codemirror/addon/edit/closebrackets.js';
    import 'codemirror/addon/fold/xml-fold.js';
    import 'codemirror/addon/edit/closetag.js';
    import 'codemirror/addon/edit/matchtags.js';
    import 'codemirror/theme/dracula.css';
    import 'codemirror/lib/codemirror.css';

    import _ from 'lodash';
    import DateService from '@/services/date.service';
    import Constants from '@/constants';

    import FullscreenEditor from './elements/FullscreenEditor';
    import TemplatePreview from './elements/TemplatePreview';

    export default {

        name: 'Edit',

        props: ['id', 'data'],

        data: () => ({
            // Step 1 controls
            formBasicValid: true,
            sendingOptions: 'schedule',
            // Step 2 controls
            formMailingValid: true,
            sweepstakeSearch: {
                id: '',
                keyword: ''
            },
            recipientsType: 'sweepstakes',
            sweepstakeMode: 'id',
            search: null,
            searchingData: false,
            searchTimeout: setTimeout(() => {}, 500),
            entries: [],
            // Other configurations
            step: 1,
            inputs: {
                title: '',
                start_date: new Date().toISOString().substr(0, 10),
                end_date: DateService.addDays(new Date(), 15).toISOString().substr(0, 10),
                subject: '',
                content: '',
                sender_name: null,
                sender_email: null,
                sweepstake_id: null,
                recipients: null
            },
            savingCreative: false,
            dateService: DateService,
            codeOptions: {
                autoRefresh: true,
                tabSize: 4,
                indentSize: 4,
                styleActiveLine: false,
                lineNumbers: true,
                autoCloseTags: true,
                line: true,
                mode: 'text/html',
                theme: 'dracula'
            },
            menu: {
                start_date: false,
                start_date_formatted: DateService.formatDate(new Date().toISOString().substr(0, 10)),
                end_date: false,
                end_date_formatted: DateService.formatDate(DateService.addDays(new Date(), 15).toISOString().substr(0, 10))
            },
            rules: {
                required: value => !!value || 'Required',
                counter: value => value.length >= 5 || 'Min 5 characters',
                url: value => {
                    const pattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
                    return pattern.test(value) || 'Invalid url';
                },
                file: value => typeof value !== 'undefined' || 'File is required'
            },
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
            isSavingBasic: false
        }),

        mounted() {
            if (this.id !== null) {
                this.setInputValues();
                if (this.data.sweepstake_id !== null) {
                    this.recipientsType = 'sweepstakes';
                    this.sweepstakeMode = 'id';
                    this.sweepstakeSearch.id = this.data.sweepstake_id;
                }
            }

            this.$refs.formBasic.validate();
            this.$refs.mailForm.validate();

            // Watch both form refs and detect it's validity changes.
            this.$watch(
                () => {
                    const  forms = {
                        basic: this.$refs.formBasic.value,
                        mailing: this.$refs.mailForm.value
                    };
                    return forms;
                },
                (formsWatcher) => {
                    this.formBasicValid = formsWatcher.basic;
                    this.formMailingValid = formsWatcher.mailing;
                }
            );


        },

        methods: {

            onInputChanged(val, inputName) {
                if (val === '') {
                    this.inputs[inputName] = null;
                }
            },

            setInputValues() {
                _.each(this.inputs, (v, k) => {
                    if (k.indexOf('date') > -1) {
                        this.inputs[k] = new Date(this.data[k]).toISOString().substr(0, 10);
                    } else {
                        this.inputs[k] = this.data[k];
                    }
                });

                this.tempFields = _.cloneDeep(this.fields);
            },

            comboChanged(evt) {
                if (typeof evt === 'object') {
                    this.inputs.sweepstake_id = evt.id;
                } else {
                    this.inputs.sweepstake_id = null;
                }
            },

            resetRecipents() {
                this.inputs.sweepstake_id = null;
                this.inputs.recipients = null;
                this.$refs.mailForm.validate();
            },

            onSweepSearch(evt) {
                window.clearTimeout(this.searchTimeout);
                if (evt === '' || evt.length < 1) {
                    this.inputs.sweepstake_id = null;
                    return;
                } else {
                    this.searchTimeout = setTimeout(() => {
                        this.searchingData = true;
                        this.$store.dispatch('sweepstakes/get', evt).then(res => {
                            this.searchingData = false;
                            this.inputs.sweepstake_id = res.id;
                        }).catch(() => {
                            this.searchingData = false;
                            this.inputs.sweepstake_id = null;
                        });
                    }, 500);
                }
            },

            saveMailing() {
                this.savingCreative = true;
                const action = this.id === null ? 'new' : 'update';
                const inputs = _.cloneDeep(this.inputs);
                if (this.sendingOptions === 'schedule') {
                    inputs.start_date += ' 00:00:00';
                    inputs.end_date += ' 23:59:59';
                }
                const fd = new FormData();
                // assign data
                fd.append('title', inputs.title);
                fd.append('start_date', inputs.start_date);
                fd.append('end_date', inputs.end_date);
                fd.append('subject', inputs.subject);
                fd.append('content', inputs.content);

                if (inputs.sender_name !== null) {
                    fd.append('sender_name', inputs.sender_name);
                }

                if (inputs.sender_email !== null) {
                    fd.append('sender_email', inputs.sender_email);
                }

                if (inputs.recipients !== null) {
                    fd.append('recipients', inputs.recipients);
                }

                if (inputs.sweepstake_id !== null) {
                    fd.append('sweepstake_id', inputs.sweepstake_id);
                }


                const data = this.id === null ? fd : {
                    id: this.id,
                    data: fd
                };

                this.$store.dispatch('massemail/' + action, data).then(response => {
                    if (action === 'new') {
                        this.$store.dispatch('massemail/get', response.id).then(() => {
                            this.$store.dispatch('massemail/getRecipients', response.id).then(() => {
                                this.savingCreative = false;
                                this.$router.replace({
                                    name: 'massemail',
                                    params: {
                                        id: response.id,
                                        selectedTab: 'recipients'
                                    }
                                });
                            }).catch(() => {
                                this.savingCreative = false;
                            });
                        }).catch(() => {
                            this.savingCreative = false;
                        });
                    } else {
                        this.$store.dispatch('massemail/getRecipients', response.id).then(() => {
                            this.savingCreative = false;
                            this.$router.replace({
                                name: 'massemail',
                                params: {
                                    id: response.id,
                                    selectedTab: 'recipients'
                                }
                            });
                        }).catch(() => {
                            this.savingCreative = false;
                        });
                    }
                }).catch(() => {
                    this.savingCreative = false;
                });
            },

            modeChanged(mode) {
                if(mode === 'schedule') {
                    this.inputs.start_date = new Date().toISOString().substr(0, 10);
                    this.inputs.end_date = DateService.addDays(new Date(), 15).toISOString().substr(0, 10);
                } else {
                    this.inputs.start_date = null;
                    this.inputs.end_date = null;
                }
            },

            onFileChanged(event) {
                if (typeof event === 'undefined') {
                    this.inputs.recipients = null;
                } else {
                    this.inputs.recipients = event;
                }
            },

            onFileClear() {
                this.inputs.recipients = null;
            },

            downloadSample() {
                const url = Constants.APP_URL + '/samples/recipients_correct.xlsx';
                window.open(url, '_blank');
            }
        },

        watch: {

            'inputs.start_date'() {
                this.menu.start_date_formatted = DateService.formatDate(this.inputs.start_date);
            },

            'inputs.end_date'() {
                this.menu.end_date_formatted = DateService.formatDate(this.inputs.end_date);
            },

            /**
             * Execute creatives search with keywords after typing finishes.
             */
            search (val) {
                this.entries = [];
                window.clearTimeout(this.searchTimeout);
                if (val !== '') {
                    this.searchTimeout = setTimeout(() => {
                        this.searchingData = true;
                        this.$store.dispatch('creatives/search', val).then(res => {
                            this.searchingData = false;
                            if (res.data.length > 0) {
                                this.entries = res.data;
                            }
                        }).catch(() => {
                            this.searchingData = false;
                        });
                    }, 500);
                }
                this.searchingData = false;
                return;
            }
        },

        computed: {
            /**
             * Returns if Finish button should be enabled or not.
             * @return {Boolean}
             */
            shouldDisableSave() {
                return this.formMailingValid && this.inputs.content !== '';
            },

            /**
             * Filter results found and return only sweepstakes from creatives matches
             * @return {Array<any>}
             */
            filteredEntries () {
                let arr = [];
                if (this.entries.length > 0) {
                    const filtered = _.filter(this.entries, (item) => {
                        return item.type === 'sweepstakes';
                    });
                    _.each(filtered, item => {
                        arr.push({
                            id: item.id,
                            title: item.title
                        });
                    });
                }

                return arr;
            },

            /**
             * Returns codemirror instance
             * @return {*}
             */
            codemirrorRef() {
                return this.$refs.myCm.codemirror;
            }
        },

        components: {
            codemirror,
            fullscreenEditor: FullscreenEditor,
            templatePreview: TemplatePreview
        }
    }
</script>
<style lang="scss" scoped>
    .hidden-textarea {
        display: none;
    }

    .vue-codemirror {
        text-align: left;
    }

    .sweepstake__options {
        background-color: transparent;
    }
</style>
