<template>
    <v-container class="recipients__tab">
        <v-card color="#303030" class="pb-10 pr-10 pl-10 recipients">
            <v-card-text>
                <v-row class=" rounded justify-center align-center flex-column">
                    <v-col cols="8">
                        <v-card color="rgba(57, 73, 171, 0.6)" shaped>
                            <v-card-title class="primary white--text">Mailing Settings</v-card-title>
                            <v-col class="d-flex align-center justify-center" cols="12">
                                <span class='text-bold text-uppercase'>
                                    Status:
                                    <strong :class="{
                                        'ml-3': true,
                                        'red--text' : !isDelivered,
                                        'green--text': isDelivered
                                        }"
                                    >{{ isDelivered ? 'Delivered' : 'Not Delivered' }}</strong>
                                </span>
                                <v-btn
                                    @click="toggleDeliver"
                                    :disabled="isRequesting"
                                    small tile outlined
                                    class="ml-6"
                                >
                                    Toggle Status <v-icon right small>fa-sync</v-icon>
                                </v-btn>
                            </v-col>

                            <v-col class="d-flex justify-center flex-row align-center" cols="12">
                                <strong v-text="'Sending options: '" class="text-uppercase"></strong>

                                <v-radio-group class="pt-0 mt-0 ml-3" v-model="sendingOptions" hide-details="auto" row dense>
                                    <v-radio color="accent" label="Schedule" value="schedule"></v-radio>
                                    <v-radio color="accent" label="Now" value="execute"></v-radio>
                                </v-radio-group>
                            </v-col>

                            <v-row class="flex-column justify-center align-center col-12" v-if="sendingOptions === 'schedule'">
                                <v-row>
                                    <v-col cols="12" class="pa-0">
                                        <label class="text-subtitle-2 pl-2 pr-2">
                                            Current Time LA:  <strong>{{ currentLATime }}</strong>
                                        </label>
                                    </v-col>
                                </v-row>
                                <v-row class="justify-center">
                                    <v-col cols="6">
                                        <v-menu
                                            v-model="menu.start_date"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    v-model="menu.start_date_formatted"
                                                    :rules="[rules.required]"
                                                    label="Select Date"
                                                    hint="MM/DD/YYYY format"
                                                    persistent-hint
                                                    prepend-icon="fa-calendar-alt"
                                                    @blur="scheduleDate = dateService.parseDate(menu.start_date_formatted)"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="scheduleDate"
                                                no-title
                                                @input="menu.start_date = false"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="3">
                                        <span>Select Hour</span>
                                        <div class="d-flex justify-center align-center">
                                            <v-digital-time-picker filled v-model="selectedTime"></v-digital-time-picker>
                                        </div>
                                    </v-col>
                                </v-row>

                            </v-row>
                            <v-col>
                                <v-btn
                                    @click="sendMails"
                                    medium tile outlined
                                    class="ml-6"
                                    :disabled="enableSending || isRequesting "
                                >
                                    {{ sendingOptions === 'schedule' ? 'Schedule ' : 'Send '}} Mails
                                    <v-icon right small>
                                        {{ sendingOptions === 'schedule' ? 'fa-calendar-check' : 'fa-location-arrow' }}
                                    </v-icon>
                                </v-btn>
                            </v-col>
                        </v-card>
                    </v-col>
                </v-row>

                <div class="recipients__title grey darken-4 elevation-15 mb-10">
                    <v-card-title class="title font-weight-light">
                        <span class="pr-2"> Recipients: </span>
                        <span class="blue--text">{{ paginator.total }}</span>
                    </v-card-title>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-btn
                            small
                            @click.native="deleteRecipients"
                            color="primary"
                            :loading="isRequesting"
                            :disabled="disableDelete || selected.length < 1"
                        >
                            Delete Recipients
                            <v-icon right small>fa-trash-alt</v-icon>
                        </v-btn>
                    </v-card-actions>
                </div>

                <v-data-table
                    v-model="selected"
                    :headers="headers"
                    :items="recipientsList"
                    :single-select="false"
                    item-key="id"
                    show-select
                    :items-per-page="paginator.per_page"
                    hide-default-footer
                    :loading="isRequesting"
                    :page.sync="currentPage"
                    class="elevation-1"
                >
                    <template v-slot:item.selected="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    v-on="on"
                                    @click="deleteSingle(item.id)"
                                    :disabled="disableDelete"
                                    icon small
                                ><v-icon small color="accent">fa-trash-alt</v-icon>
                                </v-btn>
                            </template>
                            <span>Delete</span>
                        </v-tooltip>
                    </template>
                </v-data-table>

                <v-row>
                    <v-col class="text-center pt-2">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    class="ma-2"
                                    tile large icon
                                    :disabled="currentPage === 1"
                                    @click="prev"
                                    v-on="on"
                                ><v-icon>fa-chevron-left</v-icon></v-btn>
                            </template>
                            <span>Previous Page</span>
                        </v-tooltip>

                        <span
                            class="font-weight-black blue--text"
                        >{{ currentPage }}</span>

                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    class="ma-2"
                                    tile large icon
                                    @click="next"
                                    :disabled="(currentPage === paginator.total_pages) || disableNext"
                                    v-on="on"
                                    :loading="isRequesting"
                                ><v-icon>fa-chevron-right</v-icon></v-btn>
                            </template>
                            <span>Next Page</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
    import { VDigitalTimePicker } from 'v-digital-time-picker';
    import DateService from '@/services/date.service';
    import _ from 'lodash';

    const currDate = new Date();

    export default {

        name: 'Recipients',

        props: ['id'],

        data: (vm) => ({
            dateService: DateService,
            headers: [
                {
                    text: 'EMAIL',
                    left: true,
                    value: 'email',
                    align: 'left',
                    sortable: true
                },
                {
                    text: 'ACTION',
                    value: 'selected',
                    left: true,
                    sortable: false
                }
            ],
            currentPage: 1,
            disableNext: false,
            selected: [],
            isRequesting: false,
            selectedTime: currDate.getHours() + ':' + vm.formatMinutes(currDate),
            sendingOptions: 'execute',
            scheduleDate: new Date().toISOString().substr(0, 10),
            menu: {
                start_date: false,
                start_date_formatted: DateService.formatDate(new Date().toISOString().substr(0, 10)),
            },
            rules: {
                required: value => !!value || 'Required'
            }
        }),

        mounted() {},

        methods: {
            toggleDeliver() {
                this.isRequesting = true;
                const data = {
                    creativeID: this.id,
                    status: this.creativeData.delivered ? 0 : 1
                };

                this.$store.dispatch('massemail/toggleStatus', data).then(() => {
                    this.isRequesting = false;
                }).catch(() => {
                    this.isRequesting = false;
                });
            },

            sendMails() {
                this.isRequesting = true;
                let requestData = {
                    creativeID: this.id,
                    command: this.sendingOptions
                };

                if (this.sendingOptions === 'schedule') {
                    const scheduleDate = this.scheduleDate.toString().concat(' ' + this.selectedTime);
                    requestData.data = {
                        schedule: scheduleDate
                    };
                } else {
                    requestData.data = null;
                }

                this.$store.dispatch('massemail/sendMail', requestData).then(() => {
                    this.isRequesting = false;
                }).catch(() => {
                    this.isRequesting = false;
                })
            },

            formatMinutes(dateObject) {
                return dateObject.getMinutes().toString().length < 2 ? '0' + dateObject.getMinutes().toString()  : dateObject.getMinutes();
            },

            deleteRecipients() {
                this.deleted = [];
                this.isRequesting = true;

                if (this.selected.length === 0) {
                    this.isRequesting = false;
                }

                const deleted = [];

                _.each(this.selected, item => {
                    this.$store.dispatch('massemail/deleteRecipient', {
                        creativeID: this.id,
                        recipientID: item.id
                    }).then(() => {
                        deleted.push({});
                        if (deleted.length === this.selected.length) {
                            this.isRequesting = false;
                            this.selected = [];
                        }
                    }).catch(() => {});
                });
            },

            deleteSingle(id) {
                this.isRequesting = true;

                this.$store.dispatch('massemail/deleteRecipient', {
                    creativeID: this.id,
                    recipientID: id
                }).then(() => {
                    this.isRequesting = false;
                }).catch(() => {
                    this.isRequesting = false;
                });
            },

            prev() {
                this.currentPage = this.currentPage - 1;
            },

            next() {
                if (this.recipientsList.length > (this.currentPage * this.paginator.per_page)) {
                    this.currentPage++;
                    return;
                }

                this.disableNext = true;
                this.$store.dispatch('massemail/getMoreRecipients', this.id).then(() => {
                    this.currentPage++;
                    this.disableNext = false;
                }).catch(() => {});
            }
        },

        watch: {

            scheduleDate() {
                this.menu.start_date_formatted = DateService.formatDate(this.scheduleDate);
            }
        },

        computed: {

            recipientsList() {
                return this.$store.getters['massemail/recipients'];
            },

            paginator() {
                return this.$store.getters['massemail/paginator'];
            },

            disableDelete() {
                return (this.creativeData.sweepstake_id !== null || this.isRequesting);
            },

            isDelivered() {
                return this.creativeData.delivered;
            },

            creativeData() {
                return this.$store.getters['massemail/data'];
            },

            enableSending() {
                if (this.sendingOptions === 'schedule') {
                    return this.scheduleDate === null;
                }
                return this.creativeData.delivered === 1;
            },

            currentLATime() {
                const dateTime = new Date().toLocaleString('en-US', {
                    timeZone: 'America/Los_Angeles'
                });
                const trimmed = dateTime.split(':');
                return trimmed.slice(0, -1).join(':');
            }
        },
        components: {
            VDigitalTimePicker
        }

    }
</script>

<style scoped lang="scss">
    .v-input--selection-controls.v-input--checkbox {
        margin-top: 0px;
    }
</style>
