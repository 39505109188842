<template>
    <div class="template-preview d-flex align-center">
            <v-dialog
                v-model="dialog"
                width="1080"
            >
                <template v-slot:activator="{ on: modalTrigger}">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltipActivator }">
                            <v-btn
                                small
                                v-on="{ ...tooltipActivator, ...modalTrigger}"
                            >
                                <v-icon small>fa-tv</v-icon>
                            </v-btn>
                        </template>
                        <span>Preview Content</span>
                    </v-tooltip>
                </template>

                <v-card>
                    <v-card-title class="headline primary">
                        Preview
                        <v-spacer></v-spacer>
                        <v-btn
                            color="accent"
                            text
                            outlined
                            @click="dialog = false"
                        >Close
                        </v-btn>
                    </v-card-title>
                    <v-container class="template-preview__container">
                        <div class="template-preview__container__content" v-html="content"></div>
                    </v-container>
                </v-card>
            </v-dialog>
    </div>
</template>
<script>
    export default {
        name: 'TemplatePreview',

        props: ['content'],

        data: () => ({
            dialog: false
        })
    }
</script>
<style lang="scss" scoped>
    .template-preview {
        &__container {
            width: 100%;
            height: 600px;
            max-height: 600px;
            overflow: auto;
            padding: 0;

            &__content {
                height: 100%;
                position: relative;
            }
        }
    }
</style>
