<template>
    <div class="creative creative--mass-email">
        <f-creative-container
            v-if="ready"
            :id="id"
            :tabs="tabs"
            :data="data"
        ></f-creative-container>
    </div>
</template>
<script>
    import CreativeContainer from '@/views/creatives/components/CreativeContainer';
    import Edit from '@/views/creatives/massemail/components/Edit';
    import Recipients from '@/views/creatives/massemail/components/Recipients';

    export default {
        name: 'MassEmail',
        data: () => ({
            id: null,
            tabs: [
                {
                    name: 'New',
                    key: 'edit',
                    icon: 'fa-plus-square',
                    component: Edit,
                    disabled: false
                },
                {
                    name: 'Recipients',
                    key: 'recipients',
                    icon: 'fa-address-book',
                    component: Recipients,
                    disabled: false
                }
            ],
            ready: false
        }),

        computed: {
            data() {
                return this.id !== null ? this.$store.getters['massemail/data'] : {};
            },

            loading() {
                return this.$store.getters['massemail/status'];
            }
        },

        watch: {
            '$route.params.id'() {
                this.init();
            }
        },

        mounted() {
            this.init();
        },

        destroyed() {
            this.$store.dispatch('massemail/reset').catch(() => {});
        },

        methods: {
            setInitialData() {
                const id = (this.$route.params.id === 'new' ? null : this.$route.params.id);

                this.id = id;
                this.tabs[0].name = id === null ? 'New' : 'Edit';
                this.tabs[0].icon = id === null ? 'fa-plus-square' : 'fa-edit';
                this.tabs[1].disabled = id === null;
                this.ready = false;
            },

            init() {
                this.setInitialData();

                setTimeout(() => {
                    if (this.id !== null) {
                        this.$store.dispatch('massemail/reset').then(() => {
                            this.$store.dispatch('massemail/get', this.id).then(() => {
                                this.$store.dispatch('massemail/getRecipients', this.id).then(() => {
                                    this.ready = true;
                                });
                            }).catch(() => {});
                        }).catch(() => {});
                        return;
                    } else {
                        this.ready = true;
                    }
                });
            }
        },

        components: {
            FCreativeContainer: CreativeContainer
        }
    }
</script>
