<template>
    <div class="d-flex align-center">
            <v-dialog
                v-model="dialog"
                persistent
                width="1080"
            >
                <template v-slot:activator="{ on: modalTrigger}">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltipActivator }">
                            <v-btn
                                small
                                v-on="{ ...tooltipActivator, ...modalTrigger}"
                            >
                                <v-icon small>fa-expand</v-icon>
                            </v-btn>
                        </template>
                        <span>Open in fullscreen</span>
                    </v-tooltip>
                </template>

                <v-card v-show="editorReady">
                    <v-card-title class="headline primary">
                        Editor
                        <v-spacer></v-spacer>
                        <v-btn
                            color="accent"
                            text
                            outlined
                            @click="dialog = false"
                        >Close
                        </v-btn>
                    </v-card-title>

                    <codemirror
                        ref="fsEditor"
                        @ready="onEditorReady($event)"
                        v-model="editorContent"
                        :options="editorOpts"
                    ></codemirror>
                </v-card>
            </v-dialog>
    </div>
</template>

<script>
    import { codemirror } from 'vue-codemirror';
    import 'codemirror/mode/xml/xml.js';
    import 'codemirror/addon/display/autorefresh.js';
    import 'codemirror/addon/selection/active-line.js';
    import 'codemirror/addon/edit/matchbrackets.js';
    import 'codemirror/addon/edit/closebrackets.js';
    import 'codemirror/addon/fold/xml-fold.js';
    import 'codemirror/addon/edit/closetag.js';
    import 'codemirror/addon/edit/matchtags.js';
    import 'codemirror/theme/dracula.css';
    import 'codemirror/lib/codemirror.css';

    export default {
        name: 'FullscreenEditor',

        props: ['content', 'editorOpts'],

        data: () => ({
            dialog: false,
            editorReady: false,
            editorContent: ''
        }),

        methods: {
            onEditorReady() {
                setTimeout(() => {
                    this.editorReady = true;
                    this.$refs.fsEditor.codemirror.refresh();
                });
            }
        },

        watch: {
            dialog (val) {
                if (val) {
                    this.editorContent = this.content;
                } else {
                    this.$emit('onEditorClose', this.editorContent);
                }
            }
        },

        components: {
            codemirror
        }
    }
</script>

<style lang="scss" scoped>
    .vue-codemirror {
        text-align: left;
        height: 700px;

        & ::v-deep .CodeMirror {
            height: 700px;

            &-line {
                padding-left: 30px !important;
            }
        }
    }
</style>
